import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import PostList from "../components/PostList"
import PostListShort from "../components/PostListShort"
import CoverPhoto from "../components/CoverPhoto"
import PostListTwoColumn from "../components/PostListTwoColumn"

const BlogIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <>
      <Seo title="All posts" />
      <div className="flex flex-col w-full place-content-center place-items-center">
        {/* <CoverPhoto /> */}
        {/* <div className='grid grid-cols-1 xl:grid-cols-2 gap-x-5 gap-y-10 mt-5 max-w-7xl'>
          <PostListShort posts = {posts} category={"trải nghiệm"} slug={"trai-nghiem"}/>
          <PostListShort posts = {posts} category={"tâm linh"} slug={"tam-linh"}/>
          <PostListShort posts = {posts} category={"khoa học"} slug={"khoa-hoc"}/>
          <PostListShort posts = {posts} category={"sống đẹp"} slug={"song-dep"}/>
        </div> */}
        <PostListTwoColumn posts = {posts} />
      </div>
      {/* <PostList posts={posts} /> */}
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        timeToRead
        fields {
          slug
        }
        frontmatter {
          date(formatString: "D MMMM YYYY", locale: "vi")
          title
          description
          category
          tags
          cover {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
