import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function PostListTwoColumn({ posts }) {
  console.log("Post list")
  console.log(posts)
  if (posts.length == 0) {
    return <div>No post found!</div>
  }
  return (
      <div className="max-w-5xl grid md:grid-cols-2 gird-cols-1">
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          return (
            <div
              key={title}
              className="flex flex-row flex-col- gap-x-7 transition-all duration-500 border mx-3 my-2"
            >
              <div className=" place-self-center h-44 w-32">
                <GatsbyImage
                  alt={title}
                  image={getImage(post.frontmatter.cover)!}
                  className="h-44 w-32"
                  objectPosition="50% 50%"
                />
              </div>

              <div className="flex w-full flex-col place-content-between my-2">
                <Link to={post.fields.slug}>
                  <div className="text-2xl font-bold hover:underline ">
                    {title}
                  </div>

                  <div className="pt-2">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description" 
                    />
                  </div>
                </Link>

                <div className="flex flex-col">
                  <div className="flex gap-5 text-sm md:flex-row">
                    {/* <div>{post.frontmatter.category}</div> */}
                    <div className="flex flex-row gap-3 pb-2">
                      {post.frontmatter.tags.map(tag => {
                        return (
                          // <div className="rounded-xl bg-gray-200 px-2">{tag}</div>
                          <div className=" bg-gray-200 px-2">{tag}</div>
                        )
                      })}
                    </div>
                  </div>

                  <div className="flex gap-5 text-sm md:flex-row">
                    {/* <div>{post.frontmatter.category}</div> */}
                    <div>{post.frontmatter.date}</div>
                    <div>{post.timeToRead} phút đọc</div>
                  </div>
                </div>
                
              </div>
            </div>
          )
        })}
      </div>
  )
}

PostListTwoColumn.propTypes = {
  posts: PropTypes.array,
}

export default PostListTwoColumn
